import React, { useState, useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";

import { API_UPLOAD, API_URL } from "../../config/index";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  HiSave,
  HiArrowNarrowLeft,
  HiOutlineCloudUpload,
} from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

const FormFoto = () => {
  const { id } = useParams();
  const [fotoBerita, setFotoBerita] = useState(null);
  const [fotoSumber, setFotoSumber] = useState("");
  const [previewImg, setPreviewImg] = useState(null);

  const inputFile = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    // get refresh token
    checkToken();

    getById();
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const onButtonUpload = () => {
    inputFile.current.click();
  };

  const handleChange = (e) => {
    if (e.target.files) {
      setFotoBerita(e.target.files[0]);
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
      // console.log(e.target.files[0]);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getById = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/berita/${id}`);

      setFotoSumber(response.data.fotoSumber);
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  const simpan = (e) => {
    e.preventDefault();

    const fileName = new Date().getTime();

    const formData = new FormData();
    formData.append("file", fotoBerita);
    formData.append("fileName", fileName);
    formData.append("folder", "berita");
    formData.append("x64", "tidak");

    axiosJWT
      .post(`${API_UPLOAD}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.fileName);
        axiosJWT
          .put(`${API_URL}/berita/${id}`, {
            fotoBerita: response.data.fileName,
            fotoSumber: fotoSumber,
          })
          .then((resp) => {
            Swal.fire({
              icon: "success",
              title: "Sukses!",
              text: resp.data.message,
              confirmButtonText: "Oke, lihat data!",
              willClose: () => {
                navigate(-1);
              },
            });
          })
          .catch((error) => {
            Swal.fire("Error!", error.message, "error");
          });
      })
      .catch((error) => {
        Swal.fire("Error!", error.message, "error");
      });
  };

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-poppins-semibold text-lg text-indigo-700">
          Update Foto Berita
        </h2>
        <p className="text-sm font-poppins-light text-gray-400">
          Perubahan foto berita.
        </p>
      </div>
      <form onSubmit={simpan}>
        <input
          type="file"
          id="file"
          ref={inputFile}
          className="hidden"
          onChange={handleChange}
        />
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-start-1 sm:col-span-4">
            <div className="px-4 py-6 mt-4 border border-gray-200 rounded-lg cursor-pointer bg-gray-100">
              {!fotoBerita ? (
                <div onClick={onButtonUpload}>
                  <div className="flex items-center justify-center mb-4">
                    <HiOutlineCloudUpload size={45} color="#94a3b8" />
                  </div>
                  <p className="font-poppins-semibold text-md text-black text-center">
                    Klik disini untuk mengambil gambar
                  </p>
                  <p className="font-poppins-light text-sm text-gray-400 text-center">
                    Format file yang diperbolehkan: .jpg, .jpeg, .png, .pdf
                  </p>
                </div>
              ) : (
                <div className="flex items-center">
                  <div className="flex-1">{fotoBerita.name}</div>
                  <div className="flex flex-row space-x-4">
                    <Link
                      to={previewImg}
                      target="_blank"
                      className="px-4 py-2 bg-blue-600 rounded-lg shadow-md hover:bg-blue-400"
                    >
                      <span className="text-white font-poppins-regular text-sm">
                        Preview
                      </span>
                    </Link>
                    <button
                      type="button"
                      className="px-4 py-2 bg-rose-600 rounded-lg shadow-md hover:bg-rose-400"
                      onClick={() => setFotoBerita(null)}
                    >
                      <span className="text-white font-poppins-regular text-sm">
                        Hapus
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <InputText
              label="Sumber Foto"
              name="fotoSumber"
              val={fotoSumber}
              set={setFotoSumber}
              placeholder="Masukkan keterangan asal foto"
            />
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Simpan Berita</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormFoto;
