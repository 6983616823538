import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useNavigate, useParams } from "react-router-dom";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

import { API_URL } from "../../config";

import InputText from "../../components/Form2/InputText";
import TextArea from "../../components/Form2/TextArea";
import ChooseCard from "../../components/ChooseCard";

const FormEdit = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [shortPage, setShortPage] = useState("");
  const [statusPage, setStatusPage] = useState("Draft");
  const [post, setPost] = useState(() => EditorState.createEmpty());
  const [slug, setSlug] = useState("");
  const [sideBar, setSideBar] = useState("Tidak");

  const navigate = useNavigate();

  useEffect(() => {
    // get refresh token
    checkToken();

    getById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const checkGroup = (selected) => {
    setStatusPage(selected);
  };

  const checkSidebar = (selected) => {
    setSideBar(selected);
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getById = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/page/${id}`);

      setTitle(response.data.title);
      setSlug(response.data.slug);
      setShortPage(response.data.shortPage);
      setStatusPage(response.data.statusPage);
      setSideBar(response.data.sideBar);
      const html = response.data.post;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setPost(EditorState.createWithContent(contentState));
      }
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  const simpan = (e) => {
    e.preventDefault();

    axiosJWT
      .put(`${API_URL}/page/${id}`, {
        title: title,
        shortPage: shortPage,
        post: draftToHtml(convertToRaw(post.getCurrentContent())),
        statusPage: statusPage,
        slug: slug,
        sideBar: sideBar,
      })
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: "Sukses!",
          text: resp.data.message,
          confirmButtonText: "Oke, lihat data!",
          willClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        Swal.fire("Error!", error.message, "error");
      });
  };

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-poppins-semibold text-lg text-indigo-700">
          Tambah Page
        </h2>
        <p className="text-sm font-poppins-light text-gray-400">
          Penambahan halaman page, isi informasi page dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <InputText
              label="Judul Page"
              name="title"
              val={title}
              set={setTitle}
              placeholder="Masukkan judul page"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <InputText
              label="Slug Page"
              name="slug"
              val={slug}
              set={setSlug}
              placeholder="Masukkan slug page"
            />
          </div>

          <div className="sm:col-start-1 sm:col-span-2">
            <ChooseCard
              name={statusPage}
              key="Draft"
              value="Draft"
              label="Draft"
              desc="Pilih ini jika berita belum ingin di publish"
              onClick={() => checkGroup("Draft")}
            />
          </div>
          <div className="sm:col-span-2">
            <ChooseCard
              name={statusPage}
              key="Published"
              value="Published"
              label="Publish"
              desc="Pilih ini jika berita ingin di publish"
              onClick={() => checkGroup("Published")}
            />
          </div>

          <div className="sm:col-start-1 sm:col-span-4">
            <label
              htmlFor={post}
              className="block font-poppins-regular text-xs leading-6 text-gray-400"
            >
              Isi Halaman
            </label>
            <Editor
              editorState={post}
              onEditorStateChange={setPost}
              wrapperClassName="border border-slate-200 rounded-lg"
              editorClassName="bg-gray-100 px-4 font-poppins-regular"
              toolbarClassName="rounded-lg"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <TextArea
              label="Short Post"
              name="shortPost"
              val={shortPage}
              set={setShortPage}
              rows={4}
              placeholder="Masukkan isi page singkat (deskripsi)"
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Ketik isi page yang singkat
            </p>
          </div>

          <div className="sm:col-start-1 sm:col-span-2">
            <ChooseCard
              name={sideBar}
              key="Ya"
              value="Ya"
              label="Ya"
              desc="Pilih ini jika diperlukan sidebar"
              onClick={() => checkSidebar("Ya")}
            />
          </div>
          <div className="sm:col-span-2">
            <ChooseCard
              name={sideBar}
              key="Tidak"
              value="Tidak"
              label="Tidak"
              desc="Pilih ini jika tidak diperlukan sidebar"
              onClick={() => checkSidebar("Tidak")}
            />
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Simpan Page</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEdit;
