import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import jwtDecode from "jwt-decode";

import { FcHome } from "react-icons/fc";
import SecTotal from "./secTotal";
import { API_URL } from "../../config";

const Home = () => {
  const navigate = useNavigate();

  const [totalBerita, setTotalBerita] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalKategori, setTotalKategori] = useState(0);

  useEffect(() => {
    checkToken();

    getTotalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getTotalData = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/total-data`);

      setTotalBerita(response.data.berita);
      setTotalPage(response.data.page);
      setTotalKategori(response.data.kategori);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <Header titleHead="Home" icon={<FcHome />} />

      <SecTotal
        totalBerita={totalBerita}
        totalPage={totalPage}
        totalKategori={totalKategori}
      />
    </div>
  );
};

export default Home;
