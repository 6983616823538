import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

const FormEdit = () => {
  const { id } = useParams();
  const [namaKategori, setNamaKategori] = useState("");
  const [descKategori, setDescKategori] = useState("");
  const [slugKategori, setSlugKategori] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // get refresh token
    checkToken();
    getById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getById = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/kategori/${id}`);

      setNamaKategori(response.data.namaKategori);
      setSlugKategori(response.data.slugKategori);
      setDescKategori(response.data.descKategori);
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosJWT.put(`${API_URL}/kategori/${id}`, {
        namaKategori: namaKategori,
        slugKategori: slugKategori,
        descKategori: descKategori,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: response.data.message,
        confirmButtonText: "Oke, lihat data!",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-poppins-semibold text-lg text-indigo-700">
          Update Kategori Baru
        </h2>
        <p className="text-sm font-poppins-light text-gray-400">
          Perubahan data kategori, isi informasi data dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <InputText
              label="Nama Kategori"
              name="namaKategori"
              val={namaKategori}
              set={setNamaKategori}
              placeholder="Masukkan nama kategori"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <InputText
              label="Slug Kategori"
              name="slugKategori"
              val={slugKategori}
              set={setSlugKategori}
              placeholder="Masukkan slug kategori"
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Ketik slug kategori untuk url kategori
            </p>
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <InputText
              label="Deskripsi"
              name="descKategori"
              val={descKategori}
              set={setDescKategori}
              placeholder="Masukkan deskripsi kategori"
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Ketik deskripsi tentang kategori berita
            </p>
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Update Data</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEdit;
