import React from "react";
import { HiCheck } from "react-icons/hi";

const ChooseCard = ({ name, value, label, desc, onClick }) => {
  return (
    <div
      className={`flex border rounded-lg p-3 cursor-pointer hover:border-blue-600 ${
        name === value ? "border-blue-500" : "border-slate-200"
      }`}
      onClick={onClick}
    >
      <div
        className={`w-6 h-6 rounded-full p-1 ${
          name === value ? "bg-blue-500" : "bg-slate-300"
        }`}
      >
        <HiCheck color="#FFFFFF" />
      </div>
      <div className="flex-1 ml-4">
        <div className="font-poppins-semibold text-md">{label}</div>
        <div className="font-poppins-light text-xs">{desc}</div>
      </div>
    </div>
  );
};

export default ChooseCard;
