import React from "react";

const InputText = ({ label, name, set, val }) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-poppins-light text-gray-400"
      >
        {label}
      </label>
      <input
        type="text"
        name={name}
        id={name}
        value={val}
        onChange={(e) => set(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 font-poppins-regular"
        placeholder="username"
        required
      />
    </div>
  );
};

export default InputText;
