import React from "react";
import { NavLink } from "react-router-dom";
import {
  HiOutlineInbox,
  HiFolderOpen,
  HiUserGroup,
  HiNewspaper,
  HiStatusOnline,
  HiDocumentText,
} from "react-icons/hi";
import LogoBuku from "../../assets/images/logo-buku.jpeg";

const Sidebar = () => {
  const menu = [
    { name: "Home", icon: <HiOutlineInbox size={22} />, route: "/" },
    { name: "Kategori", icon: <HiFolderOpen size={22} />, route: "/kategori" },
    { name: "Page", icon: <HiDocumentText size={22} />, route: "/page" },
    { name: "Editor", icon: <HiUserGroup size={22} />, route: "/editor" },
    { name: "Buku", icon: <HiNewspaper size={22} />, route: "/buku" },
    { name: "Iklan", icon: <HiStatusOnline size={22} />, route: "/iklan" },
  ];

  return (
    <div className="fixed z-20 h-screen border-r border-gray-200 w-64 px-6 bg-gradient-to-b from-slate-950 to-red-900">
      <img src={LogoBuku} className="w-10 mt-3 mb-6" alt="logo" />

      <div>
        <div className="mb-4 text-gray-100 text-md font-poppins-semibold">
          MAIN MENU
        </div>
        <ul className="space-y-4">
          {menu.map((val, index) => {
            return (
              <li
                key={index}
                className="flex flex-row items-center text-gray-200 font-poppins-medium text-md"
              >
                <div className="mr-4 rounded-md">{val.icon}</div>
                <NavLink
                  to={val.route}
                  className={({ isActive }) =>
                    isActive
                      ? "text-orange-300 font-poppins-semibold"
                      : "hover:text-orange-400"
                  }
                >
                  {val.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
