import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import WarningAlert from "../../components/Alert/WarningAlert";

import InputText from "../../components/Form/InputText";
import InputPassword from "../../components/Form/InputPassword";

import LogoPayolebar from "../../assets/images/logo-buku.jpeg";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);

  const navigate = useNavigate();

  const actLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const login = await axios.post(`${API_URL}/auth`, {
        username: username,
        password: password,
      });

      localStorage.setItem("tokenPL", login.data.token);
      setIsAlert(false);
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      setIsAlert(true);
      setIsLoading(false);
      setPassword("");
      if (error.response) {
        console.log(error.message);
      }
    }
  };

  return (
    <section className="bg-gradient-to-r from-slate-950 to-red-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white border rounded-2xl shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-2 md:space-y-3 sm:p-8">
            <div className="flex justify-center items-center">
              <img src={LogoPayolebar} className="w-10" alt="logo" />
            </div>
            <h2 className="text-lg font-poppins-semibold text-center">
              ADMIN PENERBIT LITERASIOLOGI
            </h2>
            {isAlert && (
              <>
                <WarningAlert description="Username dan password tidak cocok, silahkan ulangi kembali." />
              </>
            )}

            <form onSubmit={actLogin}>
              <div className="grid gap-3 mb-6 grid-cols-6 mt-10">
                <div className="col-start-1 md:col-span-6 mb-3 col-span-6">
                  <InputText
                    label="Username"
                    name="username"
                    set={setUsername}
                    val={username}
                  />
                </div>

                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <InputPassword
                    label="Password"
                    name="password"
                    set={setPassword}
                    val={password}
                  />
                </div>
              </div>
              {!isLoading ? (
                <>
                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-[#2563eb]/90 focus:ring-4 focus:outline-none focus:ring-primary-300 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center shadow-lg"
                  >
                    Masuk
                  </button>
                </>
              ) : (
                <ButtonLoading />
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
