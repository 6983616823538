import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { FcGlobe } from "react-icons/fc";

const Buku = () => {
  return (
    <div>
      <Header titleHead="Buku" icon={<FcGlobe />} />
      <Outlet />
    </div>
  );
};

export default Buku;
