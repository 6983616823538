import Root from "./root";
import Login from "../pages/Login";

import Home from "../pages/Home";

import Kategori from "../pages/Kategori";
import TableKategori from "../pages/Kategori/Table";
import FormAddKategori from "../pages/Kategori/FormAdd";
import FormEditKategori from "../pages/Kategori/FormEdit";

import Editor from "../pages/Editor";
import TableEditor from "../pages/Editor/Table";
import FormAddEditor from "../pages/Editor/FormAdd";
import FormEditEditor from "../pages/Editor/FormEdit";
import FormFotoEditor from "../pages/Editor/FormFoto";

import Buku from "../pages/Buku";
import TableBuku from "../pages/Buku/Table";
import FormAddBuku from "../pages/Buku/FormAdd";
import FormEditBuku from "../pages/Buku/FormEdit";
import AddEditor from "../pages/Buku/AddEditor";
import FormFotoBuku from "../pages/Buku/FormFoto";

import Page from "../pages/Page";
import TablePage from "../pages/Page/Table";
import FormAddPage from "../pages/Page/FormAdd";
import FormEditPage from "../pages/Page/FormEdit";

import ErrorPage from "../pages/ErrorPage";

export const Paths = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "kategori",
        element: <Kategori />,
        breadcrumb: "Kategori",
        children: [
          { index: true, element: <TableKategori />, breadcrumb: "Kategori" },
          {
            path: "add",
            element: <FormAddKategori />,
            breadcrumb: "Tambah Kategori",
          },
          {
            path: ":id",
            element: <FormEditKategori />,
            breadcrumb: "Edit Kategori",
          },
        ],
      },
      {
        path: "editor",
        element: <Editor />,
        breadcrumb: "Editor",
        children: [
          { index: true, element: <TableEditor />, breadcrumb: "Editor" },
          {
            path: "add",
            element: <FormAddEditor />,
            breadcrumb: "Tambah Editor",
          },
          {
            path: ":id",
            element: <FormEditEditor />,
            breadcrumb: "Edit Editor",
          },
          {
            path: "foto/:id",
            element: <FormFotoEditor />,
            breadcrumb: "Edit Foto",
          },
        ],
      },
      {
        path: "buku",
        element: <Buku />,
        breadcrumb: "Buku",
        children: [
          { index: true, element: <TableBuku />, breadcrumb: "Buku" },
          {
            path: "add",
            element: <FormAddBuku />,
            breadcrumb: "Tambah Buku",
          },
          {
            path: ":id",
            element: <FormEditBuku />,
            breadcrumb: "Edit Buku",
          },
          {
            path: "editor/:id",
            element: <AddEditor />,
            breadcrumb: "Tambah Editor",
          },
          {
            path: "foto/:id",
            element: <FormFotoBuku />,
            breadcrumb: "Edit Foto",
          },
        ],
      },
      {
        path: "page",
        element: <Page />,
        breadcrumb: "Page",
        children: [
          { index: true, element: <TablePage />, breadcrumb: "Page" },
          {
            path: "add",
            element: <FormAddPage />,
            breadcrumb: "Tambah Page",
          },
          {
            path: ":id",
            element: <FormEditPage />,
            breadcrumb: "Edit Page",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
];
