import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";

import { API_URL, API_UPLOAD } from "../../config/index";
import { Link, useNavigate, useParams } from "react-router-dom";

import { formatDate } from "../../function/DateFormat";

import SelectEditor from "../../components/Form2/SelectEditor";
import InputText from "../../components/Form2/InputText";
import ChooseCard from "../../components/ChooseCard";

import { FaSort } from "react-icons/fa";
import { BsFillTrashFill } from "react-icons/bs";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

const AddEditor = () => {
  const { id } = useParams();
  const [editors, setEditors] = useState([]);
  const [editorTeam, setEditorTeam] = useState([]);

  const [title, setTitle] = useState("");

  const [idEditor, setIdEditor] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [group, setGroup] = useState("");
  const [fotoProfile, setFotoProfile] = useState("");
  const [initialName, setInitialName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // get refresh token
    checkToken();

    getById();
    getAllEditors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getById = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/berita/${id}`);

      setTitle(response.data.title);
      setEditorTeam(response.data.editorteams);
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  // const getAllData = async () => {
  //   await axiosJWT
  //     .get(`${API_URL}/berita/editor/${id}`)
  //     .then((response) => {
  //       setEditorTeam(response.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  const getAllEditors = async () => {
    await axiosJWT
      .get(`${API_URL}/editor-select`)
      .then((response) => {
        setEditors(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const checkGroup = (selected) => {
    setGroup(selected);
  };

  const handleSelect = async (e) => {
    setIdEditor(e.target.value);
    try {
      const editor = await axiosJWT.get(`${API_URL}/editor/${e.target.value}`);

      setNamaLengkap(editor.data.namaLengkap);
      setGroup(editor.data.group);
      setFotoProfile(editor.data.fotoProfile);
      setInitialName(editor.data.initialName);
    } catch (error) {
      console.log(error.message);
    }
  };

  const simpan = async (e) => {
    e.preventDefault();
    try {
      axiosJWT
        .post(`${API_URL}/berita/editor/${id}`, {
          idEditor: idEditor,
          group: group,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Sukses!",
            text: response.data.message,
            confirmButtonText: "Oke, lihat data!",
            willClose: () => {
              getById();
              setIdEditor("");
              setNamaLengkap("");
              setFotoProfile("");
              setInitialName("");
              setGroup("");
            },
          });
        })
        .catch((error) => {
          Swal.fire("Error!", error.message, "error");
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteData = async (idEditor) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosJWT
          .delete(`${API_URL}/berita/editor/${id}/${idEditor}`)
          .then((response) => {
            getById();
            Swal.fire("Deleted!", response.data.message, "success");
          })
          .catch((error) => {
            Swal.fire("Deleted!", error.response.data.message, "error");
          });
      }
    });
  };

  return (
    <>
      <div className="px-10 mb-3">
        <h2 className="font-poppins-semibold text-lg text-indigo-700">
          Tambah Tim Editor
        </h2>
        <p className="text-sm font-poppins-regular text-gray-400">
          Judul Berita:{" "}
          <span className="text-black font-poppins-semibold">{title}</span>
        </p>

        <form onSubmit={simpan}>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
            <div className=" sm:col-span-4">
              <SelectEditor
                label="Editor Team"
                name="Editor"
                val={idEditor}
                set={handleSelect}
                item={editors}
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-3">
              <InputText
                label="Nama Lengkap"
                name="namaLengkap"
                val={namaLengkap}
                set={setNamaLengkap}
                placeholder="Masukkan nama lengkap"
              />
            </div>
            <div className="sm:col-span-1">
              <InputText
                label="Inisial"
                name="initialName"
                val={initialName}
                set={setInitialName}
                placeholder="Masukkan inisial"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-2">
              <ChooseCard
                name={group}
                key="Editor"
                value="Editor"
                label="Editor"
                desc="Pilih ini jika penulis seorang Editor"
                onClick={() => checkGroup("Editor")}
              />
            </div>
            <div className="sm:col-span-2">
              <ChooseCard
                name={group}
                key="Writer"
                value="Writer"
                label="Penulis"
                desc="Pilih ini jika seorang writer"
                onClick={() => checkGroup("Writer")}
              />
            </div>
          </div>
          <div className="mt-6 flex items-center gap-x-2">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiArrowNarrowLeft />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiSave />
              </span>
              <span>Tambah Editor</span>
            </button>
          </div>
        </form>
      </div>
      <div className="relative overflow-x-auto mb-3 mt-6">
        <div className="table-wrp block max-h-96">
          <table className="w-full font-poppins-regular text-xs text-left text-gray-500 border-b">
            <thead className="text-xs border-b border-t text-gray-500 bg-gray-100 sticky top-0">
              <tr>
                <th scope="col" className="p-2 border-r">
                  #
                </th>
                <th scope="col" className="px-2 py-3 border-r">
                  Foto
                </th>
                <th scope="col" className="px-2 py-3 border-r">
                  <div className="flex items-center">
                    Nama Lengkap
                    <Link to="">
                      <FaSort />
                    </Link>
                  </div>
                </th>
                <th scope="col" className="px-2 py-3 border-r">
                  <div className="flex items-center">
                    Inisial
                    <Link to="">
                      <FaSort />
                    </Link>
                  </div>
                </th>
                <th scope="col" className="px-2 py-3 border-r">
                  <div className="flex items-center">
                    Job
                    <Link to="">
                      <FaSort />
                    </Link>
                  </div>
                </th>

                <th scope="col" className="px-2 py-3">
                  <span>Aksi</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {editorTeam ? (
                <>
                  {editorTeam.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r text-center">
                        <img
                          src={`${API_UPLOAD}/editor/resize/x128/${item.idEditor.fotoProfile}`}
                          alt={item.createdAt}
                          className="w-[32px] rounded-full"
                        />
                      </td>
                      <td className="px-2 py-2 border-r">
                        <span className="font-poppins-semibold">
                          {item.idEditor.namaLengkap}
                        </span>{" "}
                        ({item.idEditor.group})
                      </td>
                      <td className="px-2 py-2 border-r">
                        {item.idEditor.initialName}
                      </td>
                      <td className="px-2 py-2 border-r">{item.group}</td>
                      <td className="px-2 py-2 text-center border-r">
                        <div className="inline-flex flex-row">
                          <button
                            type="button"
                            onClick={() => deleteData(item._id)}
                            className="font-medium text-gray-500 px-2 py-2 hover:bg-gray-200 hover:rounded-full"
                          >
                            <span>
                              <BsFillTrashFill />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AddEditor;
